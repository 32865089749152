import usePageBuilder from "../hooks/usePageBuilder";

const AdottaAlveare = (data) => {
  const { props } = usePageBuilder({
    content: data.pageContext.data.content,
    allImages: data.pageContext.data.images,
  });

  /*useEffect(() => {
    if (typeof window !== `undefined`) {
      window.dataLayer.push({
        'event': 'checkEventbriteModal',
        'eventCallback': function () {
          if (window.eventbriteModal && window.eventbriteModal.enabled) {
            setTimeout(function () {
              setModalContent(
                <EventbriteModal
                  title={window.eventbriteModal.title}
                  subtitle={window.eventbriteModal.body}
                  button={window.eventbriteModal.button}
                  eventId={window.eventbriteModal.eventId} />
              )
            }, 1000);
          }
        },
      })
    }
  }, [])*/

  /*
  useEffect(() => {
    const cookieName = 'DOWNLOAD_EBOOK'
    if (document.cookie.includes(cookieName)) {
      return
    }
    if (currentLanguage !== 'IT') {return}

    setTimeout(function () {
      setModalContent(
        <BookModal
          title={labels.BOOK_MODAL_TITLE}
          subtitle={labels.BOOK_MODAL_SUBTITLE}
          image='/2020/images/home/book.gif'
          pdfUrl='https://www.3bee.it/documents/mieli-3bee.pdf'
          cookieName={cookieName} />
      )
    }, 20000);

  }, [])
  */

  /*useEffect(() => {
    const cookieName = "DOWNLOAD_EBOOK_2";

    if (document.cookie.includes(cookieName)) {
      return;
    }
    if (currentLanguage !== "IT") { return; }

    setTimeout(function () {
      setModalContent(
        <BookModal2
          cookieName={cookieName} />,
      );
    }, 60000);
  }, []);*/

  return props.children;
};

/*  - component: Hero
    props:
      hasScroller: true
      title: <span class="color-yellow">Adotta</span> le api
      readMore:
        text: LA TECNOLOGIA AL SERVIZIO DELLA BIODIVERSITÀ
        url: /chi-siamo
      video:
        videoID: 363256912
        ctaLabel: Guarda il video
        thumb:
          mobile: /2020/images/adotta/video-thumb-mobile.jpg
          desktop: /2020/images/adotta/video-thumb-desktop.jpg
          */

export default AdottaAlveare;
